<template>
  <section id="PageSingleSeason" class="col-12 wrapper-text">
    <EpisodeTvDetail
        :tv="tv"
        :season="season"
        :episode="episode"
        :episodeCrew="episodeCrew"
        :episodeGuestStars="episodeGuestStars"
    ></EpisodeTvDetail>
    <EpisodeTV :tv="tv" :season="season"></EpisodeTV>
    <SeasonTv :tv="tv"></SeasonTv>
    <BioTv :tv="tv"></BioTv>
  </section>
</template>

<script>
import {defineAsyncComponent} from 'vue'

export default {
  name: "PageSingleSeason",
  components: {
    'BioTv': defineAsyncComponent(() =>
        import('@/components/section/BioTv')
    ),
    'SeasonTv': defineAsyncComponent(() =>
        import('@/components/section/SeasonTv')
    ),
    'EpisodeTV': defineAsyncComponent(() =>
        import('@/components/section/EpisodeTV')
    ),
    'EpisodeTvDetail': defineAsyncComponent(() =>
        import('@/components/section/EpisodeTvDetail')
    ),
  },
  watch: {
    "$route.params.episode": function (id) {
      if (this.$route.params.episode) {
        this.getEpisode(this.$route.params.id, this.$route.params.season, id);
      }
    },
  },
  computed: {
    tv() {
      return this.$store.state.getMovie.tv;
    },
    season() {
      return this.$store.state.getMovie.season;
    },
    episode() {
      return this.$store.state.getMovie.episode;
    },
    episodeCrew() {
      return this.$store.state.getMovie.episodeCrew;
    },
    episodeGuestStars() {
      return this.$store.state.getMovie.episodeGuestStars;
    },
  },

  methods: {
    getTv(id) {
      let query = `/tv/${id}?api_key=${this.$store.state.getMovie.api_key}&language=${this.$store.state.getMovie.lang}`;
      this.$store.dispatch("getMovie/fetchData", {
        query: query,
        setName: "setTv",
      });
    },
    getSeason(id, season) {
      let query = `/tv/${id}/season/${season}?api_key=${this.$store.state.getMovie.api_key}&language=${this.$store.state.getMovie.lang}`;
      this.$store.dispatch("getMovie/fetchData", {
        query: query,
        setName: "setSeason",
      });
    },
    getEpisode(id, season, episode) {
      let query = `/tv/${id}/season/${season}/episode/${episode}?api_key=${this.$store.state.getMovie.api_key}&language=${this.$store.state.getMovie.lang}`;
      this.$store.dispatch("getMovie/fetchData", {
        query: query,
        setName: "setEpisode",
      });
    },
  },
  mounted() {
    this.getTv(this.$route.params.id);
    this.getSeason(this.$route.params.id, this.$route.params.season);
    this.getEpisode(
        this.$route.params.id,
        this.$route.params.season,
        this.$route.params.episode
    );
  },
};
</script>

<style scoped></style>
